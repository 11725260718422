import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import React from "react"
import BackButton from "../components/backButton"
import Layout from "../components/layout"
import Navigation from "../components/navigation"
import SEO from "../components/seo"
import { Container } from "../style/container"
import { PagehtmlSerializer } from "../utils/pageHtmlSerializer"
import PropTypes from "prop-types"

/**
 * @param {Object} data Translations
 */
const GlossaryPage = ({
  data: {
    prismic: {
      allGlossarypages,
      allArticlesnavigations,
      allFooters,
      allKeywordss,
    },
  },
}) => {
  const {
    node: {
      title,
      text,
      meta_title,
      meta_description,
      meta_keywords,
      meta_img,
    },
  } = allGlossarypages.edges[0]
  return (
    <Layout
      keywords={allKeywordss}
      footer={allFooters}
      navigation={
        <Navigation
          title={allGlossarypages.edges[0].node.title}
          items={allArticlesnavigations.edges[0].node.body[0].fields}
          hight={"30vh"}
        />
      }
    >
      <SEO
        meta={{
          title:
            meta_title && meta_title.length > 0 ? meta_title[0].text : null,
          description:
            meta_description && meta_description.length > 0
              ? meta_description[0].text
              : null,
          keywords:
            meta_keywords && meta_keywords.length > 0
              ? meta_keywords[0].text
              : null,
          img: !!meta_img ? meta_img.url : null,
          url: window.location.pathname,
        }}
      />
      <Container>
        <RichText render={title} htmlSerializer={PagehtmlSerializer} />
        <RichText render={text} htmlSerializer={PagehtmlSerializer} />
        <BackButton blackcolor={true} align={"left"} />
      </Container>
    </Layout>
  )
}
GlossaryPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default GlossaryPage

export const pageQuery = graphql`
  query($locale: String!) {
    prismic {
      allGlossarypages(lang: $locale) {
        edges {
          node {
            title
            text
            meta_title
            meta_description
            meta_keywords
            meta_img
          }
        }
      }
      allArticlesnavigations(lang: $locale) {
        edges {
          node {
            body {
              ... on PRISMIC_ArticlesnavigationBodyMenu {
                fields {
                  url_text
                  url
                }
              }
            }
          }
        }
      }
      allKeywordss(lang: $locale) {
        edges {
          node {
            glossary
            news
            aboutus
            integritypolicy
            privacypolicy
            disclaimer
            resources
            contact
            about
          }
        }
      }
      allFooters(lang: $locale) {
        edges {
          node {
            about
            aboutus
            resources
            news
            dictionary
            legal
            disclaimer
            privacypolicy
            whatwedo
            whatwedotext
            responsiblegaming
            responsiblegamingtext
            contact
            contacttext
            bottomlinks
          }
        }
      }
    }
  }
`
